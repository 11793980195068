<template>
  <div ref="inputRef" class="w-full flex flex-col relative">
    <div class="relative flex" :class="context.classes.innerInput" @click="open">
      <input
        v-show="!isOpen"
        :value="context.value ? context.value[labelBy] : ''"
        :placeholder="placeholder"
        :class="context.classes.input"
        :disabled="context.disabled"
        readonly
      />
      <input
        v-show="isOpen"
        ref="searchRef"
        v-model="search"
        :placeholder="'Поиск'"
        :class="context.classes.input"
        :disabled="context.disabled"
      />

      <button class="pr-3" @click.prevent.stop="handleDecorationClick">
        <IconChevronDown
          v-if="!context.value && !search.length"
          class="transition-transform"
          :class="[context.classes.decoration, { 'rotate-180': isOpen }]"
        />
        <IconX v-else :class="[context.classes.decoration]" />
      </button>
    </div>
    <Transition v-bind="fadeScale" mode="out-in">
      <ul
        v-if="isOpen"
        class="absolute flex-none top-[48px] left-0 right-0 w-full translate-y-2 max-h-[200px] overflow-y-auto z-[1000] overscroll-contain"
        :class="cardStyle"
      >
        <li
          v-for="(option, i) in resources"
          :class="[
            context.classes.option,
            { selected: context.value === option[trackBy] },
            selectionHover,
          ]"
          :key="i"
          @click="handleInput(option)"
        >
          <component
            v-if="context?.slots?.item"
            :is="context?.slots?.item"
            :option="option"
          />
          <span v-else v-text="option[labelBy]" />
        </li>
        <li
          v-if="!resources.length"
          class="pointer-events-none"
          :class="[context.classes.option]"
        >
          <component v-if="context?.slots?.empty" :is="context?.slots?.item" />
          <span v-else v-text="emptyPlaceholder" />
        </li>
      </ul>
    </Transition>
    <YandexMap
      v-model="map"
      :settings="{
        location: localLocation,
        theme: context.overwriteTheme || isDark ? 'dark' : 'light',
      }"
      class="rounded overflow-hidden mt-2"
      :height="context.mapHeight || '200px'"
    >
      <YandexMapMarker
        v-if="inputValue"
        :settings="{
          coordinates: localLocation.center,
        }"
      >
        <component :is="context.slots?.marker" />
        <DefaultMarker
          v-if="!context.slots?.marker"
          :style="markerDefaultStyle"
        />
      </YandexMapMarker>

      <YandexMapDefaultFeaturesLayer />
      <YandexMapDefaultSchemeLayer />
      <YandexMapListener :settings="{ onClick: mapClick }" />
    </YandexMap>
  </div>
</template>

<script setup>
import { onClickOutside, watchDebounced } from '@vueuse/core'
import { computed, nextTick, ref, shallowRef } from 'vue'
import {
  YandexMap,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapListener,
  YandexMapMarker,
} from 'vue-yandex-maps'

import { IconChevronDown, IconX } from '@tabler/icons-vue'

import DefaultMarker from '@/components/map/DefaultMarker.vue'
import useHttp from '@/core/http.js'
import { toast } from 'vue-sonner'
import useTheme from '@/core/theme.js'

const cardStyle =
  'rounded-lg bg-card border border-black-100 dark:border-brand-3/10 shadow dark:shadow-brand-1/10'
const selectionHover =
  'transition-colors hover:text-primary hover:dark:text-white hover:bg-primary-50 hover:dark:bg-primary-900'


const fadeScale = {
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
}

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

const inputRef = ref(null)
const searchRef = ref(null)
onClickOutside(inputRef, () => (isOpen.value = false))
const inputValue = computed({
  get() {
    return props.context.value
  },
  set(val) {
    props.context.node.input(val)
  },
})
const fetchQuery = props.context.fetchQuery || ((q) => q)
const placeholder = ref(props.context.placeholder || 'Введите адрес')
const trackBy = props.context.trackBy || 'fias_id'
const labelBy = props.context.labelBy || 'title'
const enableClick = props.context.enableClick || false
const emptyPlaceholder = props.context.emptyPlaceholder || 'Ничего не найдено'
const search = ref(
  typeof inputValue.value === 'object' && inputValue.value
    ? inputValue.value[labelBy]
    : '',
)
const isOpen = ref(false)
const resources = ref([])
const isDark = useTheme()

const center = [68.9545, 33]
const zoom = 8
const map = shallowRef(null)

const markerDefaultStyle = {
  minWidth: '40px',
  position: 'relative',
  boxSizing: 'border-box',
  transform: 'translate(-50%, calc(-50% - 24px))',
}

watchDebounced(
  search,
  () => (search.value.length ? getSuggestions(search.value) : false),
  {
    debounce: 200,
    maxWait: 500,
  },
)

function checkInitialInput() {
  if (props.context.value?.latitude && props.context.value?.longitude) {
    return [props.context.value.latitude, props.context.value.longitude]
  }

  if (props.context.value?.coordinates)
    return [...props.context.value?.coordinates]
  return center
}

const localLocation = shallowRef({
  center: checkInitialInput().reverse(),
  zoom: zoom,
})

function handleDecorationClick() {
  if (!inputValue.value) {
    isOpen.value = !isOpen.value
    search.value = ''
  } else {
    isOpen.value = false
    search.value = ''
    props.context.node.input(null)
  }
}

function updateLocalLocation(
  coordinates = [],
  zoom = 10,
  duration = 1000,
  other = {},
) {
  if (!coordinates.length) {
    console.warn('coordinates not transmitted')
    return
  }
  localLocation.value = {
    center: coordinates.reverse(),
    zoom,
    duration,
    ...other,
  }
}
function handleInput(option) {
  isOpen.value = false
  updateLocalLocation([...option.coordinates], map.value.zoom)
  props.context.node.input(option)
}

function open() {
  if (search.value) {
    getSuggestions(search.value)
  }
  if (!isOpen.value) {
    isOpen.value = !isOpen.value
    nextTick(() => {
      searchRef.value.focus()
    })
  }
}

let requestController = null
function getSuggestions(query) {
  if (requestController) requestController.abort()
  requestController = new AbortController()

  useHttp('/suggestions/addresses', {
    method: 'GET',
    params: {
      query: fetchQuery(query),
    },
    signal: requestController.signal,
  }).then(({ suggestions }) => {
    resources.value = suggestions
  })
}

function mapClick(object, event) {
  if (!enableClick) return false
  useHttp('/suggestions/addresses/coords', {
    method: 'POST',
    body: {
      latitude: event.coordinates[1],
      longitude: event.coordinates[0],
    },
  }).then(({ suggestions }) => {
    if (suggestions.length) handleInput(suggestions[0])
    else {
      toast.info('Ой! Тут ничего не нашлось',{
          description: 'Попробуйте приблизить карту или найти адрес через ввод',
        }
      )
    }
  })
}
</script>
