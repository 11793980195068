<template>
  <div class="flex h-full max-w-[100vw] flex-col">
    <UIHeader v-if="$route.meta.header !== false" />
    <main
      class="relative flex-1"
      style="
        background-image: url(/images/background.svg);
        background-size: 100% auto;
      "
    >
      <RouterView v-slot="{ Component, route }">
        <Component :is="Component" :key="route.path" />
      </RouterView>
    </main>
    <UIFooter v-if="$route.meta.footer !== false" />

    <Transition v-bind="fade" mode="out-in">
      <HelpWidget v-if="renderHelpWidget" />
    </Transition>
    <ShapesSprite />
  </div>
</template>

<script setup>
import HelpWidget from '@/components/common/HelpWidget.vue'
import ShapesSprite from '@/components/common/ShapesSprite.vue'
import UIFooter from '@/components/ui/UIFooter.vue'
import UIHeader from '@/components/ui/UIHeader.vue'
import { fade } from '@/utils/transitions.js'
import { useTimeout } from '@vueuse/core'

const renderHelpWidget = useTimeout(3000)
</script>
