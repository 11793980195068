<script setup>
import { setErrors } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'

import { useAuthStore } from '@/stores/auth.js'

import { IconLoader2 } from '@tabler/icons-vue'

import Button from '@/components/glass/button'
import UIHeading from '@/components/glass/heading'
import useHttp from '@/core/http.js'
import createSocialAuthWindow from '@/utils/social.js'

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close', 'navigate'])
const form = ref({})
const auth = useAuthStore()

const { isPending, mutate } = useMutation({
  mutationFn: async (body) =>
    await useHttp('/auth/login', {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    setErrors('user-auth', error.data.errors?.global ?? [], error.data.errors)
  },
  onSuccess: ({ token }) => {
    if (!props.isMobile) {
      auth.loginWithToken(token)
      emit('close')
      return
    }
    sendEventToWebView('login', token)
  },
})

function sendEventToWebView(event, value) {
  const message = {
    action: event,
    data: value,
  }

  messageHandler.postMessage(JSON.stringify(message))
  message.type = 'opener'
  window.opener.postMessage(JSON.stringify(message), '*')
}

const mutationSocial = useMutation({
  mutationFn: async (body) =>
    await useHttp(`/auth/socials/${body.social}`, {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    // TODO: Catch
    console.log(error, 'error')
  },
  onSuccess: ({ token }) => {
    if (!props.isMobile) {
      auth.loginWithToken(token)
      emit('close')
      return
    }
    sendEventToWebView('login', token)
  },
})

function createWindow(social) {
  createSocialAuthWindow(social, async (event) => {
    if (event) {
      if (event.data.event === 'auth') {
        mutationSocial.mutate(event.data.query)
      }
    }
  })
}

function openEsiaModal() {
  createWindow('esia')
}
</script>

<template>
  <div>
    <div class="mb-8 flex items-center justify-between">
      <UIHeading title="Вход" tag="1" styles="2" />
      <button
        :disabled="mutationSocial.isPending.value"
        @click="$emit('navigate', 'register')"
      >
        Регистрация
      </button>
    </div>
    <FormKit
      id="user-auth"
      v-model="form"
      type="form"
      :actions="false"
      :disabled="mutationSocial.isPending.value"
      @submit="mutate(form)"
    >
      <FormKit label="E-mail" name="email" type="email" placeholder="E-mail" />
      <FormKit
        label="Пароль"
        name="password"
        type="password"
        placeholder="Пароль"
      />
      <div class="mb-9 flex items-center justify-between">
        <FormKit
          :classes="{ outer: '$remove:mb-4', wrapper: '$remove:mb-3' }"
          label="Запомнить меня"
          name="remember"
          type="checkbox"
        />
        <button
          :disabled="mutationSocial.isPending.value"
          @click.prevent="$emit('navigate', 'reset')"
        >
          Восстановить пароль
        </button>
      </div>
    </FormKit>
    <div class="flex flex-col gap-y-5">
      <Button
        class="w-full"
        :loading="isPending"
        :disabled="mutationSocial.isPending.value"
        text="Войти"
        size="xl"
        @click="mutate(form)"
      />
      <div class="flex flex-col gap-y-4">
        <div class="flex items-center gap-4 text-center">
          <span class="h-px w-full bg-black-100 dark:bg-black-500" />
          <span class="flex-1 whitespace-nowrap">Войти другим способом</span>
          <span class="h-px w-full bg-black-100 dark:bg-black-500" />
        </div>
        <button
          class="relative flex w-full items-center justify-center gap-2 overflow-hidden rounded-md bg-white px-7 py-3.5 text-blue-600 ring-1 ring-inset ring-blue-600"
          :disabled="mutationSocial.isPending.value"
          @click="createWindow('esia')"
        >
          <div
            v-if="mutationSocial.isPending.value"
            class="absolute flex size-full items-center justify-center bg-white"
          >
            <IconLoader2 class="size-6 animate-spin" />
          </div>
          <img
            class="flex-none"
            src="/images/socials/gosuslugi.svg"
            alt="Госуслуги"
            draggable="false"
          />
          <span>Войти через Госуслуги</span>
        </button>
      </div>
    </div>
  </div>
</template>
