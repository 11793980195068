import { defineComponent, h } from 'vue'
export default defineComponent({
  name: 'UIHeading',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    tag: {
      type: [String, Number],
      default: null,
      required: true,
      validator(value) {
        return ['1', '2', '3', '4', '5', '6', 1, 2, 3, 4, 5, 6].includes(value)
      },
    },
    styles: {
      type: [String, Number],
      default: null,
      validator(value) {
        return ['1', '2', '3', '4', '5', '6', 1, 2, 3, 4, 5, 6].includes(value)
      },
    },
  },

  setup(props) {
    const l = {
      1: 'text-4xl font-bold md:text-4.5xl',
      2: 'text-2xl font-bold md:text-3xl',
      3: 'text-xl font-bold md:text-2xl',
      4: 'text-lg font-bold md:text-xl',
      5: 'text-base font-bold md:text-lg',
    }

    return () =>
      h(
        `h${props.tag}`,
        { class: l[+props.styles || +props.tag] || 'font-semibold' },
        props.title,
      )
  },
})
