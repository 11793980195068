<script setup>
import { setErrors } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { useLocalStorage } from '@vueuse/core'
import { ref } from 'vue'
import { toast } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import { IconEye, IconEyeOff } from '@tabler/icons-vue'

import Button from '@/components/glass/button'
import UIHeading from '@/components/glass/heading'
import { useCountdown } from '@/composables/useCountdown.js'
import { useCountdownDate } from '@/composables/useCountdownDate.js'
import useHttp from '@/core/http.js'

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['navigate'])
const form = ref({})
const auth = useAuthStore()

const codeCountdown = useCountdown({ seconds: 60 })

const mutationGetCode = useMutation({
  mutationFn: async (body) =>
    await useHttp('/auth/verify', {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    setErrors(
      'user-register',
      error.data.errors?.global ?? [],
      error.data.errors,
    )
  },
  onSuccess: (result, vars) => {
    codeCountdown.start()
    toast.success(`Код отправлен на ${vars.email}`)
  },
})

const { isPending, mutate } = useMutation({
  mutationFn: async (body) =>
    await useHttp('/auth/register', {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    if (error.data && error.data.errors) {
      const fieldsToCheck = ['name', 'surname', 'patronymic']
      const errorKeys = Object.keys(error.data.errors)
      const hasError = fieldsToCheck.some((field) =>
        errorKeys.includes(field),
      )
      if (hasError) {
        error.data.errors.fio = Object.values(error.data.errors)[0]
      }
    }
    setErrors(
      'user-register',
      error.data.errors?.global ?? [],
      error.data.errors,
    )
  },
  onSuccess: ({ token }) => {
    if (!props.isMobile) {
      auth.loginWithToken(token)
      emit('close')
      return
    }
    sendEventToWebView('register', token)
  },
})

function preparingToSend(data) {
  // Извлекаем fio и address, оставляя остальные ключи
  const { fio, ...rest } = data

  return {
    ...rest, // Сохраняем все остальные ключи
    ...fio, // Разворачиваем содержимое fio
    gender: rest.gender !== 'UNKNOWN' ? rest.gender : form.value.gender,
  }
}

function sendEventToWebView(event, value) {
  const message = {
    action: event,
    data: value,
  }

  messageHandler.postMessage(JSON.stringify(message))
  message.type = 'opener'
  window.opener.postMessage(JSON.stringify(message), '*')
}

const passwordInputType = ref('password')

function switchEye({ node }) {
  passwordInputType.value = node.props.type === 'text' ? 'password' : 'text'
}
</script>

<template>
  <div>
    <div class="mb-8 flex items-center justify-between">
      <UIHeading title="Регистрация" tag="1" styles="2" />
      <button @click="$emit('navigate', 'login')">Вход</button>
    </div>
    <FormKit
      id="user-register"
      v-model="form"
      type="form"
      :actions="false"
      @submit="mutate(form)"
    >
      <FormKit label="ФИО" name="fio" type="fio" required />
      <FormKit
        label="E-mail"
        name="email"
        type="email"
        placeholder="E-mail"
        required
      />
      <FormKit
        label="Мобильный телефон"
        placeholder="Мобильный телефон"
        type="tel"
        name="phone"
        required
      />
      <div class="mb-5 grid grid-cols-12 items-start gap-5">
        <FormKit
          label="Код подтверждения"
          :classes="{ outer: '$remove:mb-4 col-span-7' }"
          placeholder="Код подтверждения"
          name="code"
          type="number"
          required
        />
        <Button
          :disabled="!form?.email || codeCountdown.isPending.value"
          class="col-span-5 mb-1 mt-6 !h-[55px]"
          :loading="mutationGetCode.isPending.value"
          :text="
            codeCountdown.isPending.value
              ? codeCountdown.remainingTime.value
              : 'Прислать код'
          "
          @click.stop.prevent="mutationGetCode.mutate({ email: form.email })"
        />
      </div>
      <FormKit
        label="Пароль"
        placeholder="•••••••••"
        name="password"
        required
        :type="passwordInputType"
      >
        <template #suffix="context">
          <button
            tabindex="-1"
            class="px-3 text-black-400 transition hover:text-black-900"
            @click.stop.prevent="switchEye(context)"
          >
            <component
              class="size-5"
              :is="context.type === 'password' ? IconEye : IconEyeOff"
            />
          </button>
        </template>
      </FormKit>
      <div class="my-9">
        <FormKit
          name="policy"
          label="Я даю согласие на обработку персональных данных"
          type="checkbox"
        />
        <FormKit
          :classes="{ outer: '$remove:mb-4', wrapper: '$remove:mb-3' }"
          name="notifications"
          label="Я даю согласие на получение важных уведомлений"
          type="checkbox"
        />
      </div>
    </FormKit>
    <Button
      class="w-full"
      size="xl"
      text="Зарегистрироваться"
      :loading="isPending"
      @click="mutate(preparingToSend(form))"
    />
  </div>
</template>
