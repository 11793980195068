import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { ru } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import { createInput } from '@formkit/vue'

import Address from '@/components/formkit/Address.vue'
import Combobox from '@/components/formkit/combobox'
import Date from '@/components/formkit/Date.vue'
import DropdownGroup from '@/components/formkit/DropdownGroup.vue'
import FIO from '@/components/formkit/FIO.vue'
import Phone from '@/components/formkit/Phone.vue'
import Select from '@/components/formkit/Select.vue'
import Switch from '@/components/formkit/Switch.vue'
import { Uploader } from '@/components/formkit/uploader'
import { asteriskPlugin, loadingPlugin } from '@/plugins/formkit-plugins.js'

import tailwindTheme from './formkit.theme.js'

export default {
  locales: { ru },
  locale: 'ru',
  icons: {
    ...genesisIcons,
    checkboxDecorator: `<svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="3"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>`,
  },
  plugins: [asteriskPlugin, loadingPlugin(), createAutoHeightTextareaPlugin()],
  config: {
    classes: generateClasses(tailwindTheme),
  },
  inputs: {
    dropdown: createInput(Select, {
      props: ['options', 'placeholder', 'icon'],
    }),
    switch: createInput(Switch),
    combobox: createInput(Combobox, {
      props: ['options', 'icon'],
    }),
    dropdownGroup: createInput(DropdownGroup),
    tel: createInput(Phone),
    fio: createInput(FIO, {
      props: ['placeholder', 'disabled', 'readonly'],
    }),
    address: createInput(Address, {
      family: 'dropdown',
      props: [
        'withoutMap',
        'overwriteTheme',
        'mapHeight',
        'enableClick',
        'fetchQuery',
      ],
    }),
    date: createInput(Date, {
      family: 'date',
      props: [
        'placeholder',
        'variant',
        'range',
        'presetRanges',
        'minRange',
        'maxRange',
        'enableTimePicker',
        'timePicker',
        'weekPicker',
        'monthPicker',
        'yearPicker',
        'utc',
        'locale',
        'is24',
        'enableSeconds',
        'monthNameFormat',
        'hideInputIcon',
        'clearable',
        'closeOnScroll',
        'autoApply',
        'markers',
        'highlight',
        'highlightWeekDays',
        'highlightDisabledDays',
        'disabled',
        'readonly',
        'noSwipe',
        'cancelText',
        'selectText',
      ],
    }),
    file: createInput(Uploader, {
      family: 'files',
      props: ['max', 'max-parallel', 'multiple', 'accept', 'max-size'],
    }),
  },
}
