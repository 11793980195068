import { useQuery } from '@tanstack/vue-query'
import { $fetch } from 'ofetch'
import { onServerPrefetch } from 'vue'
import { toast } from 'vue-sonner'

import { useGlobalStore } from '@/stores/global.js'

import isBrowser from '@/utils/is-browser.js'
import { useQueryEvents } from '@/utils/useQueryEvents.js'

import { useAuthStore } from '../stores/auth.js'

const http = $fetch.create({
  baseURL: getBaseUrl(),
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-LOCALE': 'ru', // TODO: Change
    'X-Requested-With': 'XMLHttpRequest',
    'X-TIMEZONE': Intl?.DateTimeFormat().resolvedOptions().timeZone,
  },
  onRequest: async ({ request, options }) => {
    const auth = useAuthStore()
    // Sync token
    if (auth.token) {
      options.headers = {
        ...options.headers,
        ...options.headersExtend,
        Authorization: `Bearer ${auth.token}`,
      }
    }
  },
  onResponseError({ response }) {
    if (response.status === 419) {
      toast.error('Ой!', {
        description: 'Вы делаете слишком много запросов.',
      })
    }
  },
})

export function getBaseUrl(suffix = '/api') {
  return isBrowser
    ? (import.meta.env.VITE_API_ENDPOINT ?? suffix)
    : (import.meta.env.VITE_SSR_API_ENDPOINT ?? 'http://127.0.0.1') + suffix
}

export async function useRawHttp(url, options = {}) {
  const fetchRequest = async () => {
    try {
      const global = useGlobalStore()

      // If request queried from browser and exists in global
      // state, then extract data from global state.
      if (isBrowser && options.cacheKey && global.cache[options.cacheKey]) {
        return global.cache[options.cacheKey]
      }

      const response = await http(url, options)

      // Otherwise - set data inside cache store.
      if (options.cacheKey) {
        global.cache[options.cacheKey] = response
      }

      return response
    } catch (e) {
      console.error(e.stack)
      throw e
    }
  }

  if (import.meta.env.SSR) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await fetchRequest()
        resolve(result)
      } catch (e) {
        console.error(e.stack)
        reject(e)
      }
    })
  } else {
    try {
      return await fetchRequest()
    } catch (e) {
      throw e
    }
  }
}

export default async function useHttp(url, options = {}) {
  const fetchRequest = async () => {
    try {
      const global = useGlobalStore()

      // If request queried from browser and exists in global
      // state, then extract data from global state.
      if (isBrowser && options.cacheKey && global.cache[options.cacheKey]) {
        return global.cache[options.cacheKey]
      }

      const response = await http(url, options)

      // Otherwise - set data inside cache store.
      if (options.cacheKey) {
        global.cache[options.cacheKey] = response
      }

      return response
    } catch (e) {
      if (e.cause && e.cause.name === 'AbortError') {
        console.error('Request was aborted')
      } else {
        console.error(e.stack)
        throw e
      }
    }
  }

  if (import.meta.env.SSR) {
    return new Promise((resolve, reject) => {
      onServerPrefetch(async () => {
        try {
          const result = await fetchRequest()
          resolve(result)
        } catch (e) {
          console.error(e.stack)
        }
      })
    })
  } else {
    try {
      return await fetchRequest()
    } catch (e) {
      throw e
    }
  }
}

export function useHttpQuery({
  queryOptions = {},
  url = '',
  requestOptions = {},
  callbacks = {},
}) {
  const query = useQuery({
    queryKey: [url],
    ...queryOptions,
    queryFn: async () => await useHttp(url, requestOptions),
  })
  useQueryEvents(query, callbacks)
  return query
}
