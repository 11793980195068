<script setup>
import { setErrors } from '@formkit/vue'
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { toast } from 'vue-sonner'

import { IconChevronRight } from '@tabler/icons-vue'

import Button from '@/components/glass/button'
import Heading from '@/components/glass/heading'
import useHttp from '@/core/http.js'

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close', 'navigate'])
const form = ref({})

const { isPending, mutate } = useMutation({
  mutationFn: async (body) =>
    await useHttp('/auth/reset', {
      method: 'POST',
      body,
    }),
  onError: (error) => {
    setErrors('auth-reset', error.data.errors?.global ?? [], error.data.errors)
  },
  onSuccess: () => {
    toast.success('Восстановление пароля', {
      duration: 60 * 100,
      description:
        'Ссылка для авторизации и последующей смены пароля отправлена на почту.',
    })
    if (!props.isMobile) {
      emit('close')
    }

    if(props.isMobile) sendEventToWebView('reset', true)
  },
})

function sendEventToWebView(event, value) {
  const message = {
    action: event,
    data: value
  };

  messageHandler.postMessage(JSON.stringify(message))
  message.type = 'opener'
  window.opener.postMessage(JSON.stringify(message), '*')
}
</script>

<template>
  <div>
    <!--Heading-->
    <div class="flex items-start justify-between gap-3 mb-8">
      <Heading title="Восстановление пароля" tag="2" />
      <button
        class="text-primary dark:text-white flex items-center group transition-colors hover:text-primary-800 mt-1"
        @click="$emit('navigate', 'login')"
      >
        <IconChevronRight
          class="w-0 group-hover:w-4 transition-all rotate-180 mt-0.5"
        />
        Вход
      </button>
    </div>

    <p class="text-sm mb-4 text-black/70 dark:text-white/70">
      Укажите вашу почту для восстановления пароля. На указанный адрес придет
      письмо со ссылкой для авторизации в личном кабинете.
    </p>

    <!--Form-->
    <FormKit
      id="auth-reset"
      v-model="form"
      type="form"
      :classes="{ form: 'mb-9' }"
      :actions="false"
      @submit="mutate(form)"
    >
      <FormKit label="E-mail" name="email" type="text" placeholder="E-mail" />
    </FormKit>
    <Button
      text="Восстановить"
      size="xl"
      class="w-full rounded-md"
      :loading="isPending"
      @click="mutate(form)"
    />
  </div>
</template>
