<template>
  <div class="flex-1">
    <component :is="$route.meta.layout || 'div'" />
    <Toaster
      position="top-right"
      :theme="isDark ? 'dark' : 'light'"
      rich-colors
    />
    <ModalsContainer />
  </div>
</template>

<script setup>
import { useWindowFocus } from '@vueuse/core'
import { register } from 'swiper/element/bundle'
import { useHead, useSeoMeta } from 'unhead'
import { onMounted, watch } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { toast, Toaster } from 'vue-sonner'

import { useAuthStore } from '@/stores/auth.js'

import useTheme from '@/core/theme.js'
import getTitle from '@/utils/get-title.js'
import isBrowser from '@/utils/is-browser.js'
import useSSRCookies from '@/utils/get-cookies.js'

const auth = useAuthStore()
const title = getTitle()
const isDark = useTheme()

useHead({
  titleTemplate: (pageTitle) =>
    !pageTitle ? title : `${pageTitle} | ${title}`,
})

useSeoMeta({
  description:
    '«Молодая Арктика» — это место встречи молодёжи всей Мурманской области',
  ogDescription:
    '«Молодая Арктика» — это место встречи молодёжи всей Мурманской области',
  ogTitle: 'Главная',
  ogImage: `${globalThis.STATIC_SITE_URL ?? ':static_site_url'}/images/og.png`,
})

const focused = useWindowFocus()

watch(focused, (isFocused) => {
  if (isFocused && auth.checkCookie() && !auth.user) {
    auth.loginWithToken(auth.getCookieToken())
  }
  if (isFocused && !auth.checkCookie() && auth.user) {
    auth.logout()
  }
})

onMounted(() => {
  if (isBrowser && import.meta.env.DEV) {
    if (window.VITE_ERROR) {
      toast.error(window.VITE_ERROR.title, {
        description: window.VITE_ERROR.description,
        duration: 5000,
      })
    }
  }

  // init swiper
  register()
})
</script>
