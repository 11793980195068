<script setup>
import { offset, size, useFloating } from '@floating-ui/vue'
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { IconChevronDown } from '@tabler/icons-vue'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  options: {
    type: Array,
    default: [],
  },
})

const onClickOutsideHandler = [
  () => {
    isOpen.value = false
  },
]
const reference = ref(null)
const floating = ref(null)
const { floatingStyles } = useFloating(reference, floating, {
  middleware: [
    offset(4),
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          minWidth: `${rects.reference.width}px`,
        })
      },
    }),
  ],
  placement: 'bottom',
})

const placeholder = ref(props.context.placeholder)
const isOpen = ref(false)

const emit = defineEmits(['update:model-value'])

const toggleFocus = () => {
  isOpen.value = !isOpen.value
}

const selectOption = (selectedValue) => {
  if (selectedValue !== props.context.value) {
    props.context.node.input(selectedValue)
  }
  toggleFocus()
}

const findText = (selectedValue) => {
  const option = props.context.options.find(
    (option) => option.value === selectedValue,
  )
  return option ? option.label : ''
}
</script>

<template>
  <div class="relative w-full">
    <div
      ref="reference"
      class="flex items-center gap-x-2 justify-between"
      :class="context.classes.input"
      @click="toggleFocus"
    >
      <Component v-if="context.icon" :is="context.icon" class="size-5" />
      <span class="flex-1">
        {{ [null, ''].includes(context.value) ? placeholder : findText(context.value) }}
      </span>
      <span class="toggle">
        <IconChevronDown class="size-5" />
      </span>
    </div>
    <ul
      v-if="isOpen"
      ref="floating"
      class="absolute z-50 bg-background rounded-md overflow-hidden ring-1 ring-black-100"
      :style="floatingStyles"
      v-on-click-outside="onClickOutsideHandler"
    >
      <li
        v-for="option in context.options"
        :data-value="option.value"
        class="px-3 py-2 cursor-pointer hover:bg-secondary"
        :class="{ selected: context.value === option.value }"
        @click="selectOption(option.value)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>
