<template>
  <button
    class="rounded-full shadow-sm group relative h-6 w-11 p-0.5 transition-colors"
    :class="{
      'bg-input': !modelValue,
      'bg-primary': modelValue,
    }"
    @click.prevent="handleClick"
  >
    <div
      class="rounded-full justify-center flex items-center top-0.5 absolute w-5 h-5 group-active:w-7 bg-background"
      :class="{
        'left-0.5 translate-x-0': !modelValue,
        'group-active:-left-1.5 left-0.5 translate-x-5': modelValue,
      }"
      style="
        transition:
          width 120ms ease-in-out,
          left 120ms ease-in-out,
          transform 300ms ease;
      "
    >
      <transition v-bind="transition">
        <span v-if="inactiveIcon && !modelValue" class="relative">
          <component
            :is="inactiveIcon"
            class="size-3 text-input stroke-[4] absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2"
          />
        </span>
        <span v-else-if="activeIcon && modelValue" class="relative">
          <component
            :is="activeIcon"
            class="size-3 stroke-[4] text-primary absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2"
          />
        </span>
      </transition>
    </div>
  </button>
</template>

<script setup>
import { slideLeft, slideRight } from './index.js'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  activeIcon: {
    type: [Object, String, Function],
    default: null,
  },
  inactiveIcon: {
    type: [Object, String, Function],
    default: null,
  },
})

const emits = defineEmits(['update:modelValue'])

function handleClick() {
  emits('update:modelValue', !props.modelValue)
}

const transition = computed(() => (props.modelValue ? slideRight : slideLeft))
</script>
