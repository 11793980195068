<script setup>
import { IconChevronDown } from '@tabler/icons-vue'

const props = defineProps({
  icon: {
    type: [Object, Function],
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Выбрано',
  },
  modelValue: {
    type: Array,
    default: [],
  },
  max: {
    type: Number,
    default: 2,
  },
  labelBy: {
    type: String,
    default: 'label',
  },
})
</script>

<template>
  <button
    class="inline-flex w-full gap-2 items-stretch whitespace-nowrap font-semibold text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-block dark:bg-black-800 dark:hover:bg-black-900 hover:bg-black-100 hover:text-accent-foreground rounded-md px-3 py-4"
  >
    <span class="flex items-center gap-2">
      <component
        v-if="icon"
        :is="icon"
        class="size-5 text-black-500 dark:text-black-300 flex-none"
      />
      <span v-if="label" v-text="label" />
    </span>
    <span class="flex-1">
      <span
        v-if="modelValue && modelValue.length"
        class="border-l border-black-200/80 dark:border-black-200/40 pl-2 space-x-1 flex items-center overflow-hidden"
      >
        <span
          v-if="modelValue && modelValue.length <= props.max"
          v-for="(item, i) in modelValue"
          :key="i"
          class="block w-full max-w-[60px] md:max-w-[120px] truncate overflow-hidden items-center border py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded-sm px-1 font-normal"
        >
          {{ item[labelBy] || item }}
        </span>
        <span
          v-else
          v-text="
            `${placeholder} ${modelValue && modelValue.length ? modelValue.length : ''}`
          "
        />
      </span>
    </span>
    <IconChevronDown
      class="size-5 text-black-500 dark:text-black-300 flex-none"
    />
  </button>
</template>

<style scoped></style>
